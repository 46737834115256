<template>
	<v-textarea
		:value="value"
		auto-grow
		outlined :dense="dense"
		clearable color="primary"
		hide-details="auto"
		:label="label.toUpperCase()"
		placeholder="Start typing"
		:error-messages="getErrorMessage"
		:prepend-inner-icon="icon"
		@input="$emit('input', $event)"
	/>
</template>
<script>
import FormFieldError from "@/mixins/FormFieldError.js";

export default {
	name: "TextArea",
	mixins: [FormFieldError],
	props: {
		value: {
			required: true
		},
		name: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		},
		errors: {
			type: Object,
			required: false,
			default: () => {}
		},
		icon: {
			type: String,
			required: true
		},
		dense: {
			type: Boolean,
			default: false,
			required: false,
		}
	},
	emits: ["input"]
}
</script>
